const SERVICE_ID = "service_yvbfy7c";
const TEMPLATE_ID = "template_njwb82i";
const PUBLIC_KEY = "A-gWuE01Iv29-5z39";

export const triggerEmail = async (
  e,
  setLoading,
  setButtonText,
  emailjs,
  formRef
) => {
  e.preventDefault();
  setLoading(true);
  setButtonText("Sending...");

  emailjs.sendForm(SERVICE_ID, TEMPLATE_ID, e.target, PUBLIC_KEY).then(
    (result) => {
      console.log("SUCCESS!", result.text);
      setLoading(false);
      setButtonText("Message Sent!");

      // Clear the form fields
      formRef.current.reset();

      // Reset button text after 5 seconds
      setTimeout(() => {
        setButtonText("Send Message");
      }, 5000);
    },
    (error) => {
      console.log("FAILED...", error);
      setLoading(false);
      setButtonText("Send Message");
    }
  );
};
