import React from "react";
import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom";
import Footer from "./Components/Footer";
import Navbar from "./Components/Navbar";
import routes from "./routes";
import { useEffect } from "react";
// Dynamically import components
const components = {
  Home: React.lazy(() => import("./pages/home")),
  AboutPage: React.lazy(() => import("./pages/about")),
  ServicePage: React.lazy(() => import("./pages/service")),
  ContactPage: React.lazy(() => import("./pages/contact")),
  NewsPage: React.lazy(() => import("./pages/news")),
  ViewArticle: React.lazy(() => import("./pages/viewArticle")),
  WebDevelopment: React.lazy(() => import("./pages/pricing/WebDevelopment")),
  Seo: React.lazy(() => import("./pages/pricing/Seo")),
  Smo: React.lazy(() => import("./pages/pricing/Smo")),
  Ppc: React.lazy(() => import("./pages/pricing/Ppc")),
  SeoService: React.lazy(() => import("./pages/service/SeoService")),
  SmoService: React.lazy(() => import("./pages/service/SmoService")),
  OnlineReputation: React.lazy(() =>
    import("./pages/service/OnlineReputation")
  ),
  SmallBusinessSeo: React.lazy(() =>
    import("./pages/service/SmallBusinessSeo")
  ),
  EcommerceSeo: React.lazy(() => import("./pages/service/EcommerceSeo")),
  LocalSeoService: React.lazy(() => import("./pages/service/LocalSeoService")),
  WhiteLabelSeo: React.lazy(() => import("./pages/service/WhiteLabelSeo")),
  PayPerClick: React.lazy(() => import("./pages/service/PayPerClick")),
  ContentMarketing: React.lazy(() =>
    import("./pages/service/ContentMarketing")
  ),
  AiInSeo: React.lazy(() => import("./pages/news/AiInSeo")),
  LeadingSeoTrends: React.lazy(() => import("./pages/news/LeadingSeoTrends")),
  PpcCampaign: React.lazy(() => import("./pages/news/PpcCampaign")),
  PayPerClickNews: React.lazy(() => import("./pages/news/PayPerClickNews")),
  UseSeoKeywords: React.lazy(() => import("./pages/news/UseSeoKeywords")),
  BacklinksInGoogle: React.lazy(() => import("./pages/news/BacklinksInGoogle")),
  SocialMediaOptimization: React.lazy(() => import("./pages/news/SocialMediaOptimization")),
  HighIntentKeywords: React.lazy(() => import("./pages/news/HighIntentKeywords")),
  NotFound: React.lazy(() => import("./Components/NotFound")),
};
function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}
const App = () => {
  return (
    <Router>
      <ScrollToTop />
      <Navbar />
      <React.Suspense fallback={<span className='loader'></span>}>
        <Routes>
          {routes.map(({ path, element }) => {
            const Component = components[element];
            return <Route key={path} path={path} element={<Component />} />;
          })}
        </Routes>
      </React.Suspense>
      <Footer />
    </Router>
  );
};

export default App;
