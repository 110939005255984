import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import LOGO from "../../assests/images/logo.svg";

const Navbar = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const router = useNavigate();

  const handleRegister = () => {
    router("/contact");
  };
  const handleNavLinkClick = () => {
    const navbar = document.getElementById("navbarSupportedContent");
    if (navbar) {
      navbar.classList.remove("show");
    }
  };
  
  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      
      setIsScrolled((prev) => {
        if (prev && scrollY < 130) return false;
        if (!prev && scrollY > 200) return true;
        return prev;
      });
    };
  
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  return (
    <nav
      className={`mainNavBarContainer ${
        isScrolled ? "sticky-top" : ""
      } navbar navbar-expand-lg `}
    >
      <div className='container'>
        <Link className='navbar-brand navbar-logo' to={"/"}>
          <img src={LOGO} alt='Logo' />
        </Link>
        <button
          className='navbar-toggler'
          type='button'
          data-bs-toggle='collapse'
          data-bs-target='#navbarSupportedContent'
          aria-controls='navbarSupportedContent'
          aria-expanded='false'
          aria-label='Toggle navigation'
        >
          <span className='navbar-toggler-icon'></span>
        </button>
        <div
          className='collapse navbar-collapse justify-content-end'
          id='navbarSupportedContent'
        >
          <ul className=' navbar-nav navbar-links'>
            <li>
              <Link to={"/"} onClick={handleNavLinkClick}>Home</Link>
            </li>
            <li>
              <Link to={"/about"} onClick={handleNavLinkClick}>About</Link>
            </li>
            <li className='nav-item dropdown navbarItem-hover'>
              <p
                role='button'
                data-bs-toggle='dropdown'
                aria-expanded='false'
                className="mb-0"
              >
                Services
              </p>
              <ul className='dropdown-menu dropMenu'>
                <li>
                  <Link to={"/seo-services"} onClick={handleNavLinkClick}>SEO Services</Link>
                </li>
                <li>
                  <Link to={"/smo-services"} onClick={handleNavLinkClick}>SMO Services</Link>
                </li>
                <li>
                  <Link to={"/online-reputation-management"} onClick={handleNavLinkClick}>
                    Online Reputation
                  </Link>
                </li>
                <li>
                  <Link to={"/small-business-seo"} onClick={handleNavLinkClick}>Small Business SEO</Link>
                </li>
                <li>
                  <Link to={"/ecommerce-seo"} onClick={handleNavLinkClick}>ECommerce SEO</Link>
                </li>
                <li>
                  <Link to={"/local-seo-services"} onClick={handleNavLinkClick}>Local SEO Services</Link>
                </li>
                <li>
                  <Link to={"/white-label-seo"} onClick={handleNavLinkClick}>White Label SEO</Link>
                </li>
                <li>
                  <Link to={"/pay-per-click"} onClick={handleNavLinkClick}>Pay Per Click</Link>
                </li>
                <li>
                  <Link to={"/content-marketing"} onClick={handleNavLinkClick}>Content Marketing</Link>
                </li>
              </ul>
            </li>
            <li className='nav-item dropdown navbarItem-hover'>
              <p
                role='button'
                data-bs-toggle='dropdown'
                aria-expanded='false'
                className="mb-0"
              >
                Pricing
              </p>
              <ul className='dropdown-menu dropMenu'>
                <li>
                  <Link to={"/seo-pricing"} onClick={handleNavLinkClick}>SEO Pricing</Link>
                </li>
                <li>
                  <Link to={"/smo-pricing"} onClick={handleNavLinkClick}>SMO Pricing</Link>
                </li>
                <li>
                  <Link to={"/ppc-pricing"} onClick={handleNavLinkClick}>PPC Pricing</Link>
                </li>
                <li>
                  <Link to={"/web-development-pricing"} onClick={handleNavLinkClick}>Web Development</Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to={"/news"} onClick={handleNavLinkClick}>News</Link>
            </li>
            <li>
              <Link to={"/contact"} onClick={handleNavLinkClick}>Contact</Link>
            </li>
            <li>
              <button
                className='primary-button register-btn'
                onClick={handleRegister}
              >
                Register
              </button>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};
export default Navbar;
